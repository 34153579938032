<template>
    <div class="users-list">
        <div class="tab-header">
            <div class="tab-switcher">
                <div :class="['tab-switcher-item', { active : currentSwitch == 1 }]" @click="currentSwitch = 1">{{ $t('admin.tab.doctor') }}</div>
                <div class="tab-sep"></div>
                <div :class="['tab-switcher-item', { active : currentSwitch == 2 }]" @click="currentSwitch = 2">{{ $t('admin.tab.centerUser') }}</div>
            </div>
            <div class="tab-add-btn btn" @click="openAddModal">{{ $t('admin.tab.addUser') }}</div>
        </div>
        
        <template v-if="currentSwitch == 1">
            <DoctorTable
                :users="doctors"
                v-on:updateDoctor="updateDoctor"
                v-on:deleteDoctor="deleteDoctor"
            >
            </DoctorTable>
        </template>
        <template v-if="currentSwitch == 2">
            <CoordinatorTable 
                :users="coordinators"
                v-on:updateCoordinator="updateCoordinator"
                v-on:deleteCoordinator="deleteCoordinator"
            >
            </CoordinatorTable>
        </template>
        <ModalAddUser 
            v-if="displayModalAdd"
            v-on:close="displayModalAdd = false"
            v-on:addDoctor="addDoctor"
            v-on:addCoordinator="addCoordinator"
            :selectDefault = addSelect
            :displaySwitch = true
            :selectChoice = [2,3]
        >
        </ModalAddUser>
    </div>
</template>

<script>
import DoctorTable from '../../components/tables/DoctorTable'
import CoordinatorTable from './../../components/tables/CoordinatorTable'
import ModalAddUser from './../../components/ModalAddUser'
import axios from 'axios'
import store from './../../store'

export default {
    name : 'UsersList',
    components :{
        DoctorTable,
        CoordinatorTable,
        ModalAddUser,
    },
    data() {
        return {
            currentSwitch : 1, 
            displayModalAdd : false,       
            doctors : [],
            coordinators : [],
            addSelect : 2
        }
    },
    methods : {
        addDoctor(user) {
            this.doctors.push(user)
            this.displayModalAdd = false
            this.$store.dispatch('addNotif', {type : 1, content : 1})
        },
        addCoordinator(user) {
            this.coordinators.push(user)
            this.displayModalAdd = false
            this.$store.dispatch('addNotif', {type : 1, content : 1})
        },
        updateDoctor(user) {
            this.$store.dispatch('addNotif', {type : 1, content : 2})
            this.doctors[this.doctors.findIndex(el => el.id === user.id)] = user;     
        },
        updateCoordinator(user) {
            this.$store.dispatch('addNotif', {type : 1, content : 2})
            this.coordinators[this.coordinators.findIndex(el => el.id === user.id)] = user;     
        },
        deleteDoctor(id) {
            this.doctors.splice(this.doctors.findIndex(el => el.id === id) ,1)
        },
        deleteCoordinator(id) {
            this.coordinators.splice(this.coordinators.findIndex(el => el.id === id) ,1)
        },
        openAddModal() {
            this.displayModalAdd = true
            if(this.currentSwitch == 2) {
                this.addSelect = 3
            }else {
                this.addSelect = 2
            }
        }
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"users", config)
            .then(function (response) {
                vm.doctors = response.data.filter(e => e.role_id === 3)
                vm.coordinators = response.data.filter(e => e.role_id === 2)
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .users-list {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:0 auto 80px auto;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
    }

</style>