<template>
    <div class="tab-container">
        <div class="export-row">
            <Export></Export>
        </div>
        <table>
            <thead>
                <tr>
                    <th :class="sortClasses('firstname')" @click="sortBy('firstname')">{{ $t('admin.tab.firstname')}}</th>
                    <th :class="sortClasses('lastname')" @click="sortBy('lastname')">{{ $t('admin.tab.lastname')}}</th>
                    <th :class="sortClasses('email')" @click="sortBy('email')">{{ $t('admin.tab.email')}}</th>
                    <th class="no-hover">{{ $t('admin.tab.specialties')}}</th>
                    <th class="no-hover">{{ $t('admin.tab.center')}}</th>
                    <th>{{ $t('admin.tab.action')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in userList " :key="idx">
                    <td>{{ item.firstname }}</td>
                    <td>{{ item.lastname }}</td>                    
                    <td>{{ item.email }}</td>
                    <td>{{item.specialties}}</td>
                    <td>
                        <div class="centers">
                            <div v-for="(center, idz) in item.centers" :key="idz">{{ center.centers_name }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="tab-actions">
                            <div
                                class="tab-btn tab-edit"
                                @click="openUpdate(item)"
                                v-tooltip="{ content : $t('tooltip.updateDoctor')}"
                            >
                            </div>
                            <div
                                v-if="isAdmin === 1"
                                class="tab-btn tab-delete"
                                @click="confirmModal(item.id, item.firstname, item.lastname)"
                                v-tooltip="{ content : $t('tooltip.deleteDoctor')}"
                            >
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <ModalConfirm
            v-if="displayModalConfirm"
            :contentModal="$t('admin.tab.confirmDeleteUser', {userName : contentModalConfirm })"
            v-on:close="closeConfirm"
            v-on:confirm="confirmDelete"
        >
        </ModalConfirm>
        <UpdateDoctor 
            v-if="displayModalUpdate"
            v-on:close="closeUpdate"
            v-on:updateDoctor="updateDoctor"
            :user="userUpdate"
        >
        </UpdateDoctor>
    </div>
</template>

<script>
import ModalConfirm from './../../components/ModalConfirm'
import UpdateDoctor from './../forms/UpdateDoctor'
import axios from 'axios'
import store from './../../store'
import Export from './../Export'

export default {
    name: 'DoctorTable',
    components : {
        ModalConfirm,
        UpdateDoctor,
        Export
    },
    props : ['users'],
    data() {
        return {
            displayModalConfirm : false,
            contentModalConfirm : null,
            displayModalUpdate : false,
            userUpdate : null,
            deleteId : null,
            currentSort:'lastname',
            currentSortDir:'asc',
            isAdmin : store.state.USER.role
        }
    },
    methods : {
        confirmModal(id, firstname, lastname) {
            this.displayModalConfirm =true;
            this.contentModalConfirm = firstname + ' ' + lastname ;
            this.deleteId = id
        },
        closeConfirm() {
            this.displayModalConfirm = false;
            this.contentModalConfirm = null;
            this.deleteId = null;
        },
        confirmDelete() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL +"users/"+ this.deleteId, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.$emit('deleteDoctor', vm.deleteId)
                        vm.closeConfirm();
                        store.dispatch('addNotif', {type : 1, content : 3})
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
        },
        closeUpdate() {
            this.userUpdate = null;
            this.displayModalUpdate = false;
        },
        openUpdate(user) {
            this.userUpdate = user;
            this.displayModalUpdate = true;
        },
        updateDoctor(user) {
            this.displayModalUpdate = false
            this.$emit('updateDoctor', user)
        },
        sortBy(param) {
            if(param === this.currentSort) {
                this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = param;

            this.users = this.users.sort((a,b) => {
                let modifySortDir = 1;
                if(this.currentSortDir === 'desc') modifySortDir= -1;
                if(a[param] < b[param]) return -1 * modifySortDir;
                if(a[param] > b[param]) return 1 * modifySortDir;
                return 0;
            });
        },
        sortClasses(param) {
            if(param == this.currentSort) {
                if(this.currentSortDir === 'desc') {
                    return 'sort-desc'
                }else {
                    return 'sort-asc'
                }
            }
            else {
                return 
            }            
        },
    },
    computed : {
        userList() {
            return this.users
        }
    }
 
}
</script>

<style lang="scss" scoped>
    .export-row {
        margin-top:10px;
        display: flex;
        justify-content: flex-end;
    }
</style>