<template>
    <div class="modal" @click="closeModal">
        <Loader v-if="loader"></Loader>
        <template v-else>
            <div class="modal-close" @click="closeModal" ></div>
            <div class="modal-container" @click.stop>
                <div class="modal-text" v-html="contentModal"></div>            

                <div class="modal-btn-confirm">
                    <div class="btn btn-cancel" @click="closeModal">Annuler</div>
                    <div class="btn btn-confirm" @click="confirmModal">Confirmer</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Loader from './Loader'

export default {
    name : 'Modal',
    props : ['contentModal', 'loader'],
    components : { Loader },
    methods : {
        closeModal() {
            this.$emit('close')
        },
        confirmModal() {
            this.$emit('confirm')
        }
    }
}
</script>

<style lang="scss" scoped>

    .modal {
        z-index: 999;
    }

    .modal-btn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .modal-text {
        text-align: center;
        padding:25px;
        color:$textColor;
        margin-top: 40px;
    }

    .modal-btn-confirm {
        margin-top:40px;
        display: flex;
    }

    .modal-btn-confirm .btn {
        width:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height:50px;        
        cursor: pointer;
    }

    .btn-confirm {
        background: $mainColor;
        color:#fff;
        border:1px solid $mainColor;
        border-radius:0;
        border-bottom-right-radius: 10px;
    }

    .btn-confirm.full {
        width:100%;
        border-radius:0;
        border-bottom-left-radius: 10px;
    }

    .btn-confirm:hover {
        border:1px solid $mainColor;
        color:$mainColor; 
        background: #fff;
    }

    .btn-cancel {
        background: #fff;
        color:$inputBackgroundLogin;
        border:1px solid #fff;
        border-radius: 0;
        border-bottom-left-radius: 10px;
    }

    .btn-cancel:hover {
        border:1px solid $mainColor;
        color:$mainColor; 
    }
</style>