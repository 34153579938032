<template>
    <div class="modal" @click="closeModal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="modal-container" @click.stop>
            <div class="form-add">
            <h2>{{ $t('admin.form.updateDoctorTitle')}}</h2>
            <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
            <div class="row">
                    <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50" v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="firstname" :placeholder=" $t('admin.form.firstname')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50"  v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="lastname" :placeholder=" $t('admin.form.lastname')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50|email" vid="email" v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" :placeholder=" $t('admin.form.email')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50|email|confirmed:email"  v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="emailConfirm" :placeholder=" $t('admin.form.emailConfirm')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required" v-slot="{ errors }">
                                <select v-model="centerSelected" :class="{ 'input-error' : errors[0]}">
                                    <option disabled selected class="disabled">{{ $t('admin.form.center')}}</option>
                                    <option v-for="(item, idx) in centers " :key="idx" :value="item.centers_id">{{ item.centers_name}}</option>

                                </select>   
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>                
                    <input type="submit" class="input-submit" :value="$t('admin.form.updateBtn')" />                
            </form>
            </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../../store'

export default {
    name : 'UpdateCoordinator',
    props : ['user'],
    components : {
      ValidationProvider,
      ValidationObserver
    },
    data() {
        return {
            firstname : null,
            lastname : null,
            email :null,
            emailConfirm : null,
            id : null,
            centers : null,
            centerSelected : null
        }
    },
    methods : {
        closeModal() {
            this.$emit('close')
        },
        submit() {
            let params = {
                firstname : this.firstname,
                lastname : this.lastname,
                email :this.email,
                emailConfirm : this.emailConfirm,
                center : this.centerSelected,
                role_id : 2,
                id : this.id
            }

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .put(store.getters.getURL +"users/" + this.id, params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        let user = {
                            id : params.id,
                            firstname : params.firstname,
                            lastname : params.lastname,
                            email : params.email,
                            specialties : params.specialties,
                            role_id : params.role_id,
                            centers : [response.data.centers]
                        }
                        vm.$emit('updateCoordinator',user)
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
        }
    },
    mounted() {
        this.firstname = this.user.firstname;
        this.lastname = this.user.lastname;
        this.email = this.user.email;
        this.emailConfirm = this.user.email;
        this.centerSelected = this.user.centers[0].centers_id
        this.id = this.user.id
        
        //récupération des centres
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"centers", config)
            .then(function (response) {
                vm.centers = response.data               
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
    
}
</script>

<style lang="scss" scoped>
    .form-add h2 {
        margin-top: 40px;
    }

    .modal-container {
        max-height: 90%;
        max-width:1000px;
        width:90%;
    }
</style>